@import './variables';

.ds_site-branding__logo-image {
  filter: invert(0) !important;
}

@media (min-width: 576px) {
  .ds_site-header__content {
    .ds_site-branding__logo {
      height: $scts-logo-link-height;
    }
    .ds_site-branding__logo-image {
      height: $scts-logo-image-height !important;
    }
  }
}

pre {
  white-space: pre-line;
  font-family: 'Roboto', 'Helvetica Neue', 'Helvetica', 'Arial', 'sans-serif' !important;
  font-size: $scts-font-default !important;
}

.ds_wrapper {
  padding: 0 0.5rem !important;
  margin: 0 !important;
}

.header-text {
  margin-top: 0.5rem;
}

.ds_page-header {
  margin-top: 1rem !important;

  .ds_site-header__content {
    padding: 0 0.5rem;
  }
}

.ds_page-header h1.ds_page-header__title {
  display: inline-block;
  //font-weight: 500;
  padding-top: .5rem;
  position: relative;
}

.ds_page-header h1.ds_page-header__title::after {
  background-color: #0065bd;
  content: "";
  height: 4px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  bottom: -10px;
  top: auto!important;
}

main {
  a {
    color: $scts-primary-colour !important;
  }
}

footer {
  margin-top: 1.5rem;
}

.ds_pagination__list {
  float: right;
  margin-bottom: 0.75rem !important;
}

.borderless-card {
  border: none !important;
}

.ds_contact-details__title::after {
  top: auto !important;
  bottom: -10px;
}

@media (min-width: 768px) {
  .ds_wrapper {
    width: 100% !important;
  }
}

.ds_input--fluid-one-third {
  min-width: 80%;
}

.filter-row {
  display: flex;
  justify-content: space-between;
}

.filter-row .filter {
  width: 360px;
}

.filter-row .filter div div {
  max-width: 100% !important;
}

.search-row {
  justify-content: space-between;
}

.search-row-r-aligned-buttons {
  align-self: baseline;
}

.search-row .search {
  width: 100%;
}

.search-row .clear-button {
  justify-content: left;
}

.search-row .clear-button button.ds_button, .search-row .excel-export-button button.ds_button {
  height: 48px !important;
  min-height: 48px;
  min-width: 156px;
  width: 100%;
}

.ds_select-arrow {
  background-color: #105b7d!important;
}

.hearings-table.parent-table > thead > tr > th:first-of-type, .decisions-table.parent-table > thead > tr > th:first-of-type {
  width: 3.33rem;
}

.hearings-table table th, .decisions-table table th {
  padding: .5rem 1rem .5rem .5rem;
}

@media (max-width: 768px) {
  .filter-row, .search-row {
    flex-wrap: wrap;
    font-size: 0.9rem  }
  .filter-row div.filter, .search-row div.search, .search-row div.clear-button, .search-row-r-aligned-buttons, .search-row-r-aligned-buttons div {
    display: block;
    width: 100%;
  }
}

@media (min-width: 769px) {
  .filter {
    margin-right: 1rem;
  }
  .search-row-r-aligned-buttons {
    display: flex;
  }
  .search-row .search-row-r-aligned-buttons {
    justify-content: flex-end;
  }
  .search-row-r-aligned-buttons .excel-export-button, .search {
    margin-right: 1rem;
  }
}

.search-row .clear-button button, .search-row .excel-export-button button{
  padding-top: .8rem;
  padding-bottom: 1.33rem;
}

.ds_select-arrow:before {
  border-width: 0 0 3px 3px !important;
}
.ds_accordion-item__indicator {
  border-width: 0 0 .1875em .1875em !important;
}

.nr-of-results {
  font-weight: bold;
  font-style: italic;
}