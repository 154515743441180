.apply-tabs {
  .tab-content {
    ul {
      list-style-type: none;
      padding: 0;
      margin: 0;
    }
    .upper-trib {
      margin-bottom: 1.5rem;
    }
  }
}
