.decisions-table {
  
  tr {
    td {
      overflow-wrap: break-word;
      a {
        text-decoration: none;
      }
    }
  }
}

@media (max-width: 575px) {
  .decisions-table {
    .decisions-table-nonmobile {
      display: none;
    }
    .decisions-table-mobile {
      display: table-cell;
    }
  }
}
@media (min-width: 576px) {
  .decisions-table {
    .decisions-table-nonmobile {
      display: table-cell;
    }
    .decisions-table-mobile {
      display: none;
    }
  }
}

.decisions-table tr td a {
  position: relative;
}

.decisions-table td:nth-of-type(2) {
  word-break: break-all;
}

.decisions-filter-row .filter:nth-of-type(4) {
  margin-right: 0;
}

@media (min-width: 769px) and (max-width: 1300px) {
  .decisions-filter-row {
    flex-wrap: wrap;
  }
  .decisions-filter-row .filter {
    margin-right:0;
    min-width: 252px;
    width: 48.5%;
  }
  .decisions-filter-row .filter:nth-of-type(1) {
    margin-right: 1rem;
  }
}