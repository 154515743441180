$scts-primary-colour: #105b7d;
$scts-primary-colour-highlight: #193358;
$scts-secondary-colour: #f7f7f7;
$scts-secondary-colour-medium: #e7e7e7;
$scts-secondary-colour-dark: #ddd;
$scts-tab-border-colour: #ddd;
$scts-card-header-colour: #fff;
$scts-box-shadow-light: 0 0 3px 1px $scts-secondary-colour;
$scts-box-shadow-medium: 0 0 3px 1px $scts-secondary-colour-medium;
$scts-box-shadow-dark: 0 0 3px 1px $scts-secondary-colour-dark;
$scts-box-shadow-table: 8px -2px 0px 5px $scts-secondary-colour-medium;
$scts-logo-link-height: 2.8rem;
$scts-logo-image-height: 3rem;
$scts-font-default: 1rem;

$sg-yellow: #fdd522;
$sg-accordion-button-colour: #333333;
